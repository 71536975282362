.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #342831;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.img_pos {
  padding-left: 5mm;
  width: 60px;
  height: 40px;
}

.img_logo_pos {
  padding-left: 5mm;
  width: 233px;
  height: 105px;
}

.title_pos {

  color: #000F61;
  font-family: Helvetica;
  font-size: x-large;
  font-weight: 500;
  padding-left: 5mm;
  margin-inline: initial;
}

.title_resume {
  color: #1a80e6;
  font-family: Roboto;
  font-size: large;
  font-weight: 500;
  padding-left: 5mm;

  margin-inline: initial;
}

.label1_pos {
  padding-top: 5mm;
  padding-left: 5mm;
  color: #000F61;
  font-size: medium;
  font-weight: 300;
  font-size: 15px;
}

.btn_pos {
  padding-top: 15mm;
  padding-left: 20mm;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } 
}



/* secction user manager */
